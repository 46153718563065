import axios from "axios";
import router from "@/router";
import paths from "@/router/paths";
import qs from "qs";

export default class NetworkService {
  baseUrl = process.env.VUE_APP_API_BASE_URL;
  endpoints = {
    login: "login",
    users: "users",
    forgotPassword: "password/email",
    settings: "settings",
    homeInfo: "home",
    subscription: "stripe-subscriptions",
    passwordReset: "password/reset",
    notifications: "notification",
    validateEmail: "users/email-verification",
    plans: "plans",
  };
  constructor() {}

  async post(endPoint, params) {
    try {
      const res = await axios.post(this.baseUrl + endPoint, params, {
        headers: this.headers(),
      });
      return this.handleResponse(res);
    } catch (e) {
      this.handleError(e);
    }
  }
  async get(endPoint, query = {}, defaultToken = "") {
    const queryParams = new URLSearchParams();
    if (query.page) {
      queryParams.append("page", query.page);
    }
    if (query.search) {
      queryParams.append("q", query.search.trim());
    }
    if (query.orderBy && query.orderBy.length) {
      queryParams.append("orderBy", query.orderBy[0].sortName);
      queryParams.append("orderByDirection", query.orderBy[0].order);
    }

    delete query.search;
    delete query.orderBy;
    delete query.page;

    Object.keys(query).map((key) => {
      queryParams.append(`${key}`, query[key]);
    });

    let url = `${this.baseUrl}${endPoint}?${queryParams.toString()}`;

    try {
      const res = await axios.get(url, {
        headers: this.headers("", defaultToken),
      });
      return this.handleResponse(res);
    } catch (e) {
      this.handleError(e);
    }
  }
  async put(endPoint, params) {
    try {
      const res = await axios.put(this.baseUrl + endPoint, params, {
        headers: this.headers(),
      });
      return this.handleResponse(res);
    } catch (e) {
      this.handleError(e);
    }
  }
  async delete(endPoint) {
    try {
      const res = await axios.delete(this.baseUrl + endPoint, {
        headers: this.headers(),
      });
      return this.handleResponse(res);
    } catch (e) {
      this.handleError(e);
    }
  }

  async postEncoded(endPoint, params) {
    try {
      const res = await axios.post(
        this.baseUrl + endPoint,
        qs.stringify(params),
        { headers: this.headers("urlencoded") }
      );
      return this.handleResponse(res);
    } catch (e) {
      this.handleError(e);
    }
  }

  async putEncoded(endPoint, params) {
    var token = localStorage.getItem("token");
    console.log(token);
    if (token == null) {
      token = "";
    }
    try {
      const res = await axios.put(
        this.baseUrl + endPoint,
        qs.stringify(params),
        { headers: this.headers("urlencoded") }
      );
      return this.handleResponse(res);
    } catch (e) {
      this.handleError(e);
    }
  }

  async postMultipart(endPoint, params) {
    try {
      const res = await axios.post(
        this.baseUrl + endPoint,
        this.makeItMultipartParams(params),
        { headers: this.headers("multipart") }
      );
      return this.handleResponse(res);
    } catch (e) {
      this.handleError(e);
    }
  }

  async putMultipart(endPoint, params) {
    var token = localStorage.getItem("token");
    console.log(token);
    if (token == null) {
      token = "";
    }
    try {
      const res = await axios.put(
        this.baseUrl + endPoint,
        this.makeItMultipartParams(params),
        { headers: this.headers("multipart") }
      );
      return this.handleResponse(res);
    } catch (e) {
      this.handleError(e);
    }
  }
  makeItMultipartParams(params) {
    var p = new FormData();
    Object.keys(params).forEach(function (key, index) {
      if (Array.isArray(params[key])) {
        params[key].map((r, i) => {
          Object.keys(r).forEach((k) => {
            p.append(`${key}[${i}][${k}]`, r[k]);
          });
        });
      } else {
        p.append(key, params[key]);
      }
    });
    return p;
  }

  makeExternalRequest(type, endPoint, params, headers = {}) {
    switch (type) {
      case "post":
        return axios.post(endPoint, params, { headers });

      case "get":
        return axios.get(endPoint, { headers });

      case "put":
        return axios.put(endPoint, params, { headers });
    }
  }
  headers(encodeType = "", defaultToken = "") {
    var access_token =
      localStorage.getItem(process.env.VUE_APP_API_TOKEN_KEY) || defaultToken;
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (encodeType == "multipart") {
      delete headers["Content-Type"];
      // headers['Content-Type'] = `multipart/form-data`
    }
    if (encodeType == "urlencoded") {
      headers["Content-Type"] = `application/x-www-form-urlencoded`;
    }
    if (access_token) {
      headers["Authorization"] = `Bearer ${access_token}`;
    }
    return headers;
  }

  handleResponse(res) {
    if (res.data.status === false) {
      this.handleError(res);
    } else {
      return res.data;
    }
  }
  handleError(error) {
    const e = error.response || error;
    console.log("ERRRRRROOOOOOOOOOOOOOOO", e);
    if (e && e.status && e.status == 401) {
      const appRedirect = router.currentRoute.query.appRedirect;
      const query = appRedirect ? "?appRedirect=true" : "";
      router.push(`${paths.login}${query}`);
    }
    let message =
      e?.data?.message || e?.message || "Ocorreu um erro, tente novamente";
    if (e?.data?.errors) {
      const keys = Object.keys(e.data?.errors);
      message = e.data?.errors[keys[0]][0];
      console.log("meeeeeeeessage", message);
    }
    throw { message };
  }
}
