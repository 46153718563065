<template>
  <div class="form-group">
    <div class="d-flex justify-content-between">
      <label v-if="label" class="form-label text-dark text-label"> {{ label }} <i v-if="required"
          class="text-danger">*</i>
      </label>
      <slot name="labelContentEnd"></slot>
    </div>
    <div class="row cursor-pointer" v-for="(item, index) in options" @click="handleSelected(item)" :key="index">
      <div class="col-12">
        <div class="row">
          <div class="col">{{ item.name }}</div>
          <div class="col-auto">
            <span v-if="selectedValue && selectedValue.id == item.id" class="fas fa-check text-primary fa-mdsize"></span>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div class="invalid-feedback">
      <small>{{ errorMsg }}</small>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'MoneyInput',
  props: {
    options: {
      required: true,
      type: []
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String | Number,
      default: ''
    },
    errorMsg: {
      type: String,
      default: 'Por favor, preencha este campo corretamente'
    },
    inputStyle: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },

  },
  watch: {
    model(val) {
      this.selectedValue = this.options.find(elem => elem.id == val)
    },
    options() {
      this.selectedValue = this.options.find(elem => elem.id == this.model)
    }
  },
  mounted() {
    this.model = this.value || ''
  },
  data() {
    return {
      isFromApp: this.$route.query.isFromApp,
      selectedValue: null,
      model: ''
    }
  },
  methods: {
    handleSelected(item) {
      this.selectedValue = item
      this.$emit('onSelectBoxChanged', item.id)
    }
  }
}
</script>