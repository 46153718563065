import AppContainer from "@/components/AppContainer.vue";
import EmptyState from "@/components/EmptyState.vue";
import FormButton from "@/components/FormComponents/FormButton.vue";
import PasswordInput from "@/components/FormComponents/PasswordInput.vue";
import TextInput from "@/components/FormComponents/TextInput.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import LoadingContainer from "@/components/LoadingContainer.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import NavBar from "@/components/NavBar.vue";
import NotificationToast from "@/components/NotificationToast.vue";
import SearchBar from "@/components/SearchBar.vue";
import SideBar from "@/components/SideBar.vue";
import TableHead from "@/components/TableHead.vue";
import UserService from "@/services/UserService";
import { EventBus } from "@/utils/EventBus";
import * as helper from "@/utils/Helpers";
import "@fortawesome/fontawesome-free/css/all.css";
import { StripePlugin } from "@vue-stripe/vue-stripe";
import "bootstrap";
import "jquery";
import * as moment from "moment";
import "moment/locale/pt-br";
import VueMask from "v-mask";
import money from "v-money";
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./assets/theme/scss/theme.scss";
import ConfirmationAlert from "./components/ConfirmationAlert/ConfirmationAlert.vue";
import MoneyInput from "./components/FormComponents/MoneyInput.vue";
import RadioInput from "./components/FormComponents/RadioInput.vue";
import SelectBoxInput from "./components/FormComponents/SelectBoxInput.vue";
import SelectInput from "./components/FormComponents/SelectInput.vue";
import TextAreaInput from "./components/FormComponents/TextAreaInput.vue";
import YesOrNoInput from "./components/FormComponents/YesOrNoInput.vue";
import router from "./router";
import paths from "./router/paths";
import store from "./store";
import "./styles.scss";
import "./utils/Prototypes/prototypeFunctions";
import AboutAppModal from "./views/AboutApp/AboutAppModal/AboutAppModal.vue";
import SubscriptionModal from "./views/SubscriptionModal/SubscriptionModal.vue";

const options = {
  pk: `${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`,
  apiVersion: "2022-11-15",
  // apiVersion: "2023-10-16"
  locale: "pt-BR",
};

Vue.use(StripePlugin, options);

Vue.use(VueCryptojs);
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(money);

Vue.prototype.$helper = helper;
Vue.prototype.$paths = paths;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$userService = new UserService();
Vue.prototype.$moment = moment;
Vue.prototype.$envString = process.env.VUE_APP_ENV_STRING;

Vue.component("TextInput", TextInput);
Vue.component("TextAreaInput", TextAreaInput);
Vue.component("PasswordInput", PasswordInput);
Vue.component("FormButton", FormButton);
Vue.component("AppContainer", AppContainer);
Vue.component("NavBar", NavBar);
Vue.component("SideBar", SideBar);
Vue.component("ModalComponent", ModalComponent);
Vue.component("NotificationToast", NotificationToast);
Vue.component("LoadingContainer", LoadingContainer);
Vue.component("MoneyInput", MoneyInput);
Vue.component("SelectBoxInput", SelectBoxInput);
Vue.component("SelectInput", SelectInput);
Vue.component("HeaderComponent", HeaderComponent);
Vue.component("EmptyState", EmptyState);
Vue.component("SearchBar", SearchBar);
Vue.component("TableHead", TableHead);
Vue.component("ConfirmationAlert", ConfirmationAlert);
Vue.component("SubscriptionModal", SubscriptionModal);
Vue.component("AboutAppModal", AboutAppModal);
Vue.component("YesOrNoInput", YesOrNoInput);
Vue.component("RadioInput", RadioInput);

Vue.config.productionTip = false;

function toBase64URL(json) {
  const jsonString = JSON.stringify(json);
  const btyeArray = Buffer.from(jsonString);
  return btyeArray
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
