import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
import paths from "./paths";
Vue.use(VueRouter);

function isLogged() {
  return store.state.userSession ? true : false;
}

function isEmailVerified() {
  return store.state.userSession.email_verified_at;
}

// function authCheck(to, from, next) {
//   if (isLogged()) {
//     if (isEmailVerified()) {
//       next({ path: to });
//     } else {
//       next({ path: paths.emailConfirmation });
//     }
//   } else {
//     next({ path: paths.login });
//   }
// }

function authCheck(to, from, next) {
  if (isLogged()) {
    // Verifica se ele confirmou o email
    if (isEmailVerified()) {
      // Verifica se a query '?appRedirect=true' está presente na URL de origem
      if (from.query.appRedirect) {
        // Evita loop
        if (to.query.appRedirect) {
          next();
        } else {
          // Se estiver vindo do app, adiciona a flag 'appRedirect' ao objeto de rota de destino
          next({ ...to, query: { ...to.query, appRedirect: true } });
        }
      } else {
        // Se não estiver vindo do app, continua normalmente
        next();
      }
    } else {
      next({ path: paths.emailConfirmation });
    }
  } else {
    next({ path: paths.login });
  }
}

const routes = [
  // LOGIN
  {
    path: paths.login,
    component: () => import("@/views/Login/Login.vue"),
    props: true,
  },

  // ESQUECI A SENHA
  {
    path: paths.passwordRecovery,
    component: () => import("@/views/PasswordRecovery/PasswordRecovery.vue"),
    props: true,
  },

  // CADASTRO
  {
    path: paths.register,
    component: () => import("@/views/Register/Register.vue"),
    props: true,
  },

  // CCONFIRMAÇÃO DE  EMAIL
  {
    path: paths.emailConfirmation,
    component: () => import("@/views/EmailConfirmation/EmailConfirmation.vue"),
    props: true,
  },

  // VERIFICAR EMAIL
  {
    path: paths.emailVerification,
    component: () => import("@/views/EmailVerification/EmailVerification.vue"),
    props: true,
  },

  // RECADASTRAR SENHA
  {
    path: paths.passwordReset,
    component: () => import("@/views/PasswordReset/PasswordReset.vue"),
    props: true,
  },

  // HOME
  {
    path: "/",
    redirect: {
      name: "Plans",
    },
    beforeEnter: authCheck,
  },

  {
    path: paths.home,
    name: "Home",
    component: () => import("@/views/Home/Home.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // PLANOS
  {
    name: "Plans",
    path: paths.plans,
    component: () => import("@/views/Plans/Plans.vue"),
    props: true,
  },

  // ASSINATURA
  {
    path: `${paths.subscription}/:id`,
    component: () => import("@/views/Subscription/Subscription.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // SUBSCRIPTION CONFIRMATION
  {
    path: paths.subscriptionConfirmation,
    name: "SubscriptionConfirmation",
    component: () => import("@/views/SubscriptionConfirmation/SubscriptionConfirmation.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // MINHA CONTA
  {
    path: paths.myAccount,
    component: () => import("@/views/MyAccount/MyAccount.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // ALTERAR SENHA
  {
    path: paths.passwordUpdate,
    component: () => import("@/views/PasswordUpdate/PasswordUpdate.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // MEU PLANO
  {
    path: paths.myPlan,
    component: () => import("@/views/MySubs/MySubs.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // SOBRE O APP
  {
    path: paths.aboutApp,
    component: () => import("@/views/AboutApp/AboutApp.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // NOTIFICAÇÕES
  {
    path: paths.notifications,
    component: () => import("@/views/Notifications/Notifications.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // VIEW CONFIGURAÇÕES
  {
    path: `${paths.settings}/:key`,
    component: () => import("@/views/Settings/Settings.vue"),
    props: true,
  },

  // NOT FOUND
  {
    path: "*",
    redirect: {
      name: "NotFound",
    },
  },
  {
    path: paths.not_found,
    name: "NotFound",
    component: () => import("@/views/NotFound/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  paths,
});

export default router;
