<template>
  <div id="app">
    <router-view />
    <NotificationToast />
    <ConfirmationAlert />
    <SubscriptionModal />
    <AboutAppModal />
  </div>
</template>

<script>
import AboutAppModal from "./views/AboutApp/AboutAppModal/AboutAppModal.vue";

export default {
  name: "App",
  created() {
    document.title = "Bluu | Web App";
  },
};
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
