<template>
  <div class="form-group">
    <div class="d-flex justify-content-between">
      <label v-if="label" class="form-label text-dark text-label"> {{ label }} <i v-if="required" class="text-danger">*</i>
      </label>
      <slot name="labelContentEnd"></slot>
    </div>
    <input class="form-control" v-model.lazy="model.$model" v-money="config"
      :maxlength="maxLength" :class="{ 'is-invalid': model.$error, 'border-0 p-0': isFromApp }" type="text"
      :placeholder="placeholder" :style="inputStyle" />
    <div class="invalid-feedback">
      <small>{{ errorMsg }}</small>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'MoneyInput',
  props: {
    model: {
      required: false,
      default: () => { }
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    errorMsg: {
      type: String,
      default: 'Por favor, preencha este campo corretamente'
    },
    inputStyle: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      isFromApp: this.$route.query.isFromApp,
      config: {
        prefix: '',
        thousands: '',
        decimal: ',',
        precision: 2,
        disableNegative: true,
        minimumNumberOfCharacters: 1,
        modelModifiers: {
          number: true
        }
      }
    }
  }
}
</script>