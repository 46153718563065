function dateMask(value) {
  const month = [/[0-1]/, value.charAt(0) === "0" ? /[1-9]/ : /[0-2]/];
  const year = [/[0-9]/, /[0-9]/];
  return [...month, "/", ...year];
}

function validarNumeroCartao(numeroCartao) {
  let numeroCartaoLimpo = numeroCartao.replace(/\s/g, ""); // Remover espaços em branco, se houver

  if (!/^\d+$/.test(numeroCartaoLimpo)) {
    return false;
  }

  let numeroCartaoReverso = numeroCartaoLimpo.split("").reverse().join("");
  let digitosImpares = [];
  let digitosPares = [];

  for (let i = 0; i < numeroCartaoReverso.length; i++) {
    if (i % 2 === 0) {
      digitosImpares.push(parseInt(numeroCartaoReverso[i], 10));
    } else {
      let digitoPar = parseInt(numeroCartaoReverso[i], 10) * 2;
      if (digitoPar > 9) {
        digitoPar -= 9;
      }
      digitosPares.push(digitoPar);
    }
  }

  let somaDigitos =
    digitosImpares.reduce((acc, curr) => acc + curr, 0) +
    digitosPares.reduce((acc, curr) => acc + curr, 0);
  return somaDigitos % 10 === 0;
}

function prepareHtmlContent(textWithLink, isFromApp) {
  let txt = textWithLink;
  let replacements = [
    {
      target: new RegExp("<p><br></p>", "gi"),
      replaceWith: '<p style="margin: 0; padding: 0" ></p>',
    },
    {
      target: new RegExp(`${process.env.VUE_APP_CMS_URL}`, "gi"),
      replaceWith: "",
    },
  ];
  replacements.map((r) => {
    txt = txt.replace(r.target, r.replaceWith);
  });
  return txt;
}

function fixHtmlStyles() {
  // Função auxiliar para verificar se a tag pai tem um id específico
  function hasSpecificIdParent(element, parentId) {
    let parent = element.parentNode;
    while (parent !== null) {
      if (parent.id === parentId) {
        return true;
      }
      parent = parent.parentNode;
    }
    return false;
  }

  // Adiciona classes apenas se a tag estiver dentro de um elemento com id específico
  Array.from(document.getElementsByTagName("a")).forEach((tag) => {
    if (hasSpecificIdParent(tag, "htmlToFix")) {
      tag.classList.add("text-warning");
      tag.classList.add("bg-warning25");
    }
  });

  Array.from(document.getElementsByTagName("li")).forEach((tag) => {
    if (hasSpecificIdParent(tag, "htmlToFix")) {
      tag.classList.add("word-break-word");
    }
  });

  Array.from(document.getElementsByTagName("img")).forEach((tag) => {
    if (hasSpecificIdParent(tag, "htmlToFix")) {
      tag.classList.add("w-100");
    }
  });
}

// Array.from(document.getElementsByTagName('a')).map(tag => {
//     tag.classList.add('text-warning')
//     tag.classList.add('bg-warning25')
// })
// Array.from(document.getElementsByTagName('li')).map(tag => {
//     tag.classList.add('word-break-word')
// })
// Array.from(document.getElementsByTagName('img')).map(tag => {
//     tag.classList.add('w-100')
// })

export { dateMask, validarNumeroCartao, prepareHtmlContent, fixHtmlStyles };
