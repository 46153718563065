const paths = {
  not_found: "/not-found",
  home: "/home", // MUDAR ISSO DEPOIS
  login: "/login",
  register: "/cadastrar",
  passwordRecovery: "/esqueci-a-senha",
  passwordReset: "/password/reset",
  subscription: "/assinatura",
  subscriptionConfirmation: "/criada-com-sucesso",
  plans: "/planos",
  myPlan: "/meu-plano",
  settings: "/configuracoes",
  aboutApp: "/sobre-o-app",
  myAccount: "/minha-conta",
  passwordUpdate: "/alterar-senha",
  notifications: "/notificações",
  emailConfirmation: "/confirmar-email",
  emailVerification: "/verificar-email",
};

export default paths;
